import React, {FC} from 'react';

import {FormatCurrency} from 'components/currency/format-currency';
import {calculateProductUnitPrice} from 'utils/quotes/calculator';

interface ProductUnitPriceProps {
    price: number;
    commissionMultiplier: number;
};

export const ProductUnitPrice: FC<ProductUnitPriceProps> = React.memo(({price, commissionMultiplier}) => {
    return <FormatCurrency number={calculateProductUnitPrice(price)} />;
});
