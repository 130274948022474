import {FC} from 'react';

import {FormatCurrency} from 'components/currency/format-currency';
import {MinimalQuotedPaymentMethodFragment, MinimalQuotedProductFragment} from 'generated/graphql';
import {calculatePaymentMethodTotalPrice} from 'utils/quotes/calculator';

interface PaymentMethodTotalPriceProps {
    products: MinimalQuotedProductFragment[];
    paymentMethod: Omit<MinimalQuotedPaymentMethodFragment, 'originalPaymentMethod'>;
    dealer?: boolean;
};

export const PaymentMethodTotalPrice: FC<PaymentMethodTotalPriceProps> = ({products, paymentMethod, dealer = false}) => {
    const price = calculatePaymentMethodTotalPrice(products, paymentMethod, undefined, dealer);
    return <FormatCurrency number={price} />;
};
