import {Theme} from '@mui/material/styles'
import {FC} from 'react'
import {makeStyles} from 'tss-react/mui'

import {useMobile} from 'core/hooks'

import {LazyProductCategoryFromSubcategory, LazyProductSubCategory} from '.'

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        '& > * + *': {
            marginTop: theme.spacing(0.5),
        }
    },

    category: {
        fontWeight: 500
    },

    subcategory: {
        fontStyle: 'italic',
    }
}));

interface SubcategoryDisplayProps {
    id: string;
}

export const SubcategoryDisplay: FC<SubcategoryDisplayProps> = ({id}) => {
    const {classes} = useStyles();
    const isMobile = useMobile();

    return (
        <div className={classes.root}>
            <div className={!isMobile ? classes.category : classes.subcategory}>
                <LazyProductCategoryFromSubcategory id={id} />
            </div>
            <div className={!isMobile ? classes.subcategory : classes.category}>
                <LazyProductSubCategory id={id} />
            </div>
        </div>
    );
};
