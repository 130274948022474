import HelpIcon from '@mui/icons-material/Help'
import {Button, Dialog, DialogActions, DialogContent, Grid, Typography} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import React, {FC} from 'react'
import {makeStyles} from 'tss-react/mui'

const useStyles = makeStyles()(theme => ({
    title: {
        paddingBottom: '10px',
    },
    multiline: {
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
    },
}))

interface ProductDetailModalProps {
    productName: string
    productDescription?: string
}

export const ProductDetailModal: FC<ProductDetailModalProps> = ({productName, productDescription}) => {
    const {classes} = useStyles()

    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    if (!productDescription) return null

    return (
        <span>
            <IconButton
                onClick={handleOpen}
                color='primary'
                size='large'>
                <HelpIcon />
            </IconButton>
            <Dialog
                open={open}
                maxWidth={'md'}
                onClose={handleClose}>
                <DialogContent>
                    <Grid
                        container={true}
                        item={true}
                        xs={12}
                        direction='column'
                        justifyContent='flex-start'
                        alignItems='flex-start'>
                        <Grid item={true}>
                            <Typography
                                className={classes.title}
                                variant='h6'>
                                {productName}
                            </Typography>
                        </Grid>
                        <Grid item={true}>
                            <Typography
                                gutterBottom={true}
                                variant='body2'
                                className={classes.multiline}>
                                {productDescription}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Fechar</Button>
                </DialogActions>
            </Dialog>
        </span>
    )
}
