import {Grid} from '@mui/material'
import {FC} from 'react'
import {makeStyles} from 'tss-react/mui'

import {FormatCurrency} from 'components/currency/format-currency'
import {useMobile} from 'core/hooks'

const useStyles = makeStyles()(() => ({
    label: {
        fontWeight: 500,
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
    }
}))

interface AdditionalChargesProps {
    quoteAdditionalCharges?: any
    quoteAdditionalChargesDescription?: string
}

export const QuoteAdditionalCharges: FC<AdditionalChargesProps> = ({quoteAdditionalCharges, quoteAdditionalChargesDescription}) => {
    const {classes} = useStyles()
    const isMobile = useMobile()

    return (
        <Grid container={true} spacing={1} direction='row' justifyContent='space-between' alignItems='flex-start'>
            <Grid item={true} container={true} xs={isMobile ? 6 : 2} spacing={1} direction='column' justifyContent='flex-start'>
                <Grid item={true} className={!isMobile && classes.label || ''}>Total</Grid>
                <Grid item={true} className={isMobile && classes.label || ''}><FormatCurrency number={quoteAdditionalCharges} /></Grid>
            </Grid>
            <Grid item={true} container={true} xs={isMobile ? 6 : 10} spacing={1} direction='column' justifyContent='flex-start'>
                <Grid item={true} className={!isMobile && classes.label || ''}>Descrição</Grid>
                <Grid item={true} className={isMobile && classes.label || ''}>{quoteAdditionalChargesDescription}</Grid>
            </Grid>
        </Grid>
    );
}
