import {CircularProgress} from '@mui/material'
import React, {FC, useEffect, useState} from 'react'

import {DisplayTaxes} from 'components/taxes/display-taxes'
import {
    AdditionalTaxInfoType,
    MinimalQuotedPaymentMethodFragment,
    MinimalQuotedProductFragment, ProductTaxFragment,
    QuotesQuoteBillingTypeChoices,
} from 'generated/graphql'
import {calculateTaxesAggregate, getMultiplierToApply} from 'utils/quotes/calculator'

interface PaymentMethodTotalTaxProps {
    products: MinimalQuotedProductFragment[]
    billingType: string
    taxes: ProductTaxFragment[]
}

export const PaymentMethodTotalTax: FC<PaymentMethodTotalTaxProps> = ({
    products,
    billingType,
    taxes,
}) => {
    const [taxAggregate, setTaxAggregate] = useState<{name: string, amount: number}[]>([])

    useEffect(() => {
        if (taxes) setTaxAggregate(calculateTaxesAggregate(taxes, products))
    }, [taxes])

    if (!taxes.length) return <CircularProgress color='primary' />

    return <DisplayTaxes taxes={taxAggregate} billingType={billingType as QuotesQuoteBillingTypeChoices} showTotal={true} />
}
