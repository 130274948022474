import React, {FC} from 'react';

import {FormatCurrency} from 'components/currency/format-currency';
import {calculateProductTotalPrice, calculateProductTotalPriceForDealer} from 'utils/quotes/calculator';

interface ProductTotalPriceProps {
    dealer?: boolean;
    price: number;
    quantity: number;
    commissionMultiplier: number;
    taxes?: {name: string, amount: number;}[];
    isSale?: boolean;
}

export const ProductTotalPrice: FC<ProductTotalPriceProps> = React.memo(({price, quantity, commissionMultiplier, dealer, taxes, isSale}) => {
    return <FormatCurrency number={!dealer
        ? calculateProductTotalPrice(price, quantity, taxes)
        : calculateProductTotalPriceForDealer(price, commissionMultiplier, quantity, taxes)}
           />;
});
