import {Button, Dialog, DialogActions, DialogContent, Grid, Tooltip, useMediaQuery} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {FC, useEffect, useState} from 'react'
import {makeStyles} from 'tss-react/mui'

import {useGetProductsImagesLazyQuery} from 'generated/graphql'

interface ProductDisplayProps {
    productName: string
    productDescription?: string
    productImage?: string
    externalId?: string
    assisted?: boolean
    showDescription?: boolean
}

const useStyles = makeStyles()((theme: Theme) => ({
    product: {
        fontWeight: 500,
        maxWidth: theme.spacing(20),
        '@media print': {
            fontSize: '0.625rem'
        },
    },

    description: {
        fontStyle: 'italic',
        maxWidth: theme.spacing(20),
    },

    imageLink: {
        paddingTop: theme.spacing(1),
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        cursor: 'pointer',
    },

    imageContainer: {
        justifyContent: 'space-evenly',
    },

    image: {
        objectFit: 'contain',
        maxWidth: 'inherit',
    },

    dialogContent: {
        paddingTop: '0px!important',
    },

    thumbnail: {
        maxWidth: theme.spacing(10),
        marginRight: theme.spacing(2),
        verticalAlign: 'middle',
    }
}))

export const ProductDisplay: FC<ProductDisplayProps> = ({productName, productDescription, externalId, productImage, assisted, showDescription}) => {
    const {classes} = useStyles()
    const [showImageModal, setShowImageModal] = useState(false)
    const handleClose = () => setShowImageModal(false)
    const print = useMediaQuery('print')

    const [getProductImage, {data: productImageData}] = useGetProductsImagesLazyQuery()

    useEffect(() => {
        if (externalId && showImageModal) getProductImage({variables: {req: {products: [externalId]}}})
    }, [externalId, showImageModal])

    const image = productImage || productImageData?.productsImages[0].image

    return (
        <div>
            <div>
                <Tooltip title={productDescription || ''}>
                    <div className={classes.product}>
                        {assisted && image && <a target='blank' href={image}><img className={classes.thumbnail} src={image} /></a>}
                        {productName}
                    </div>
                </Tooltip>
                {showDescription && productDescription && !print && (
                    <div className={classes.description}>
                        {productDescription}
                    </div>
                )}
                {!assisted && !print && (
                    <div className={classes.imageLink}>
                        <a className={classes.imageLink} onClick={() => setShowImageModal(!showImageModal)}> Mostrar produto</a>
                    </div>
                )}
            </div>
            <span>
                <Dialog
                    open={showImageModal}
                    maxWidth={'md'}
                    onClose={handleClose}>
                    <DialogContent className={classes.dialogContent}>
                        <Grid
                            container={true}
                            item={true}
                            xs={12}
                            className={classes.imageContainer}>
                            <img className={classes.image} alt={productName} src={image} />
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>
                            Fechar
                        </Button>
                    </DialogActions>
                </Dialog>
            </span>
        </div>
    )
}
