import {Button, Dialog, DialogContent, Grid, Typography, GridProps} from '@mui/material'
import {useRouter} from 'next/router'
import React, {FC} from 'react'

import {useUser} from 'context/user'
import {useToggle} from 'core/hooks'
import {ExpiredQuotesForm} from 'forms/quotes/expired/expired-quotes-form'
import {GetQuoteDocument, QuoteDetailFragment, TryAndBuyTryAndBuyStatusChoices} from 'generated/graphql'

interface QuoteCloseProps {
    quote: QuoteDetailFragment
    children?: React.ReactElement
    gridProps?: GridProps
}

export const QuoteClose: FC<QuoteCloseProps> = ({quote, children, gridProps}) => {
    const {open, toggle} = useToggle()
    const {user} = useUser()
    const router = useRouter()
    const {id} = router.query
    const allowedTryAndBuyStatus = [
        TryAndBuyTryAndBuyStatusChoices.Expired,
        TryAndBuyTryAndBuyStatusChoices.Returned,
        TryAndBuyTryAndBuyStatusChoices.Rejected,
    ]

    if (!user?.permissions.manageQuotes || quote.closeReason || quote.sale || quote.supplyOrder) return null
    if (quote.tryAndBuy && !allowedTryAndBuyStatus.includes(quote.tryAndBuy?.status)) return null

    const component = (
        <>
            {children && React.cloneElement(children, {onClick: () => toggle()}) ||
                <Button variant='text' color='primary' onClick={toggle}>
                    Fechar cotação
                </Button>
            }
            <Dialog
                open={open}
                maxWidth='lg'
                fullWidth={true}
                onClose={toggle}>
                <DialogContent>
                    <Grid
                        container={true}
                        justifyContent='flex-start'
                        alignItems='flex-start'
                        spacing={2}>
                        <Grid item={true} xs={12}>
                            <Typography variant='h6'>
                                Fechar cotação
                            </Typography>
                        </Grid>
                        <Grid item={true} xs={12}>
                            <ExpiredQuotesForm
                                quotes={[quote]}
                                onSubmit={toggle}
                                onCancel={toggle}
                                refetchQueries={[{query: GetQuoteDocument, variables: {id: id as string}}]}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )

    if (!gridProps) return component

    return (
        <Grid item={true} {...gridProps}>
            {component}
        </Grid>
    )
}
