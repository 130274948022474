import {FC} from 'react';

import {FormatCurrency} from 'components/currency/format-currency';
import {MinimalQuotedProductFragment, ProductTaxType} from 'generated/graphql';
import {calculateProductsTotalPrice, calculateProductsTotalPriceForDealer} from 'utils/quotes/calculator';

interface ProductsTotalPriceProps {
    dealer?: boolean;
    products: MinimalQuotedProductFragment[];
    taxes?: ProductTaxType[] | undefined;
};

export const ProductsTotalPrice: FC<ProductsTotalPriceProps> = ({products, dealer, taxes}) => {
    const price = !dealer
        ? calculateProductsTotalPrice(products, taxes)
        : calculateProductsTotalPriceForDealer(products, taxes);
    return <FormatCurrency number={price} />;
};
