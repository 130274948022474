import {FC} from 'react';

import {FormatCurrency} from 'components/currency/format-currency';
import {QuotedPaymentMethodFragment, QuoteListFragment} from 'generated/graphql';
import {calculatePaymentMethodTotalPriceWithTaxDisplay} from 'utils/quotes/calculator';

interface QuoteTotalPriceProps {
    quote: Partial<QuoteListFragment>;
    paymentMethod?: QuotedPaymentMethodFragment;
};

export const QuoteTotalPrice: FC<QuoteTotalPriceProps> = ({quote, paymentMethod}) => {
    if (paymentMethod) {
        const total = quote.products && calculatePaymentMethodTotalPriceWithTaxDisplay(quote.products, paymentMethod) || 0;
        return (
            <div key={paymentMethod.id} style={{whiteSpace: 'nowrap'}}>
                {paymentMethod.slug}: <FormatCurrency number={total} />
            </div>
        );
    }
    return (
        <>
            {quote.paymentMethods?.map(paymentMethod => {
                const total = quote.products && calculatePaymentMethodTotalPriceWithTaxDisplay(quote.products, paymentMethod) || 0;
                return (
                    <div key={paymentMethod.id} style={{whiteSpace: 'nowrap'}}>
                        {paymentMethod.slug}: <FormatCurrency number={total} />
                    </div>
                );
            })}
        </>
    );
};
