import {ExpandMore} from '@mui/icons-material'
import {
    Box,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Theme,
    Typography,
    useMediaQuery,
} from '@mui/material'
import React, {FC, useMemo} from 'react'
import {makeStyles} from 'tss-react/mui'

import {FormatCurrency} from 'components/currency/format-currency'
import {useUser} from 'context/user'
import {QuotesQuoteBillingTypeChoices} from 'generated/graphql'

type DisplayTaxesProps = {
    taxes: ({name: string, amount: number})[]
    billingType: string
    showTotal?: boolean
    commissionDisclaimer?: boolean
}

export const groupTaxesByName = (taxes: DisplayTaxesProps['taxes']) => {
    return taxes.reduce<DisplayTaxesProps['taxes']>((acc, tax) => {
        const existing = acc.find(t => t.name === tax.name)
        if (existing) {
            existing.amount += tax.amount
        } else {
            acc.push(tax)
        }
        return acc
    }, [])
}

export const calculateDisplayTotalTaxes = (taxes: DisplayTaxesProps['taxes']) =>
    taxes?.reduce((total, tax) => tax.name === 'DIFAL' || tax.name === 'ICMS_ST_FRONTEIRA' ? total : total + tax.amount, 0) || 0

export const DisplayTaxesCaption: FC<Pick<DisplayTaxesProps, 'billingType' | 'commissionDisclaimer'>> = ({billingType, commissionDisclaimer}) => {
    const print = useMediaQuery('print')
    const {user} = useUser()
    const {dealerCommission: extraCommissionByGroup = 0, name: groupName} = user?.organizations?.reduce<(typeof user)['organizations'][number]['group']>(
        (acc, curr) => {
            return (curr.group.dealerCommission || 0) > (acc.dealerCommission || 0) ? curr.group : acc
        },
        user?.organizations?.[0]?.group || {},
    ) || {}

    return (
        <caption>
            <Typography variant='caption' color='textSecondary'>
                {`* Imposto ${billingType.toUpperCase() === QuotesQuoteBillingTypeChoices.Indirect ? 'ICMS_ST_FRONTEIRA' : 'DIFAL'} não incluso na NF`}
                {!print && commissionDisclaimer && billingType.toUpperCase() === QuotesQuoteBillingTypeChoices.Direct && (
                    <>
                        <br />
                        {!!extraCommissionByGroup && `** Comissão extra por ser ${groupName}`}
                    </>
                )}
            </Typography>
        </caption>
    )
}

export const DisplayTaxes: FC<DisplayTaxesProps> = ({taxes, billingType, showTotal}) => {
    const {classes} = useStyles()
    const total = useMemo(() => calculateDisplayTotalTaxes(taxes), [taxes])

    return (
        <span>
            {taxes?.map(tax => (
                (
                    (billingType === QuotesQuoteBillingTypeChoices.Direct || billingType === QuotesQuoteBillingTypeChoices.Urgency)
                    && tax.name !== 'DIFAL'
                )
                || (billingType === QuotesQuoteBillingTypeChoices.Indirect && tax.name !== 'ICMS_ST_FRONTEIRA')
            ) && (
            <p className={classes.subText}>
                {tax.name}: <FormatCurrency number={tax.amount} />
            </p>
                )
            )}
            {showTotal && <p className={classes.subText}>Total: <FormatCurrency number={total} /></p>}
            {(billingType === QuotesQuoteBillingTypeChoices.Direct || billingType === QuotesQuoteBillingTypeChoices.Urgency)
                && (
                    <p className={classes.subText}>
                        (DIFAL <FormatCurrency number={taxes?.find(tax => tax.name === 'DIFAL')?.amount} />)*
                    </p>
                )
            }
            {billingType === QuotesQuoteBillingTypeChoices.Indirect
                && (
                    <p className={classes.subText}>
                        (ICMS_ST_FRONTEIRA <FormatCurrency number={taxes?.find(tax => tax.name === 'ICMS_ST_FRONTEIRA')?.amount} />)*
                    </p>
                )
            }
        </span>
    )
}

export const DisplayTaxesAccordion: FC<Omit<DisplayTaxesProps, 'showTotal'>> = ({taxes, billingType}) => {
    const {classes} = useStyles()
    const total = useMemo(() => calculateDisplayTotalTaxes(taxes), [taxes])

    return (
        <Accordion classes={{expanded: classes.expanded}}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                classes={{content: classes.summaryContent}}>
                Total taxes:&nbsp;<strong><FormatCurrency number={total} /></strong>
            </AccordionSummary>
            <AccordionDetails>
                <Box display='flex' flexDirection='column'>
                    {taxes?.map(tax => (
                        (
                            (billingType === QuotesQuoteBillingTypeChoices.Direct || billingType === QuotesQuoteBillingTypeChoices.Urgency)
                            && tax.name !== 'DIFAL'
                        )
                        || (billingType === QuotesQuoteBillingTypeChoices.Indirect && tax.name !== 'ICMS_ST_FRONTEIRA')
                    ) && (
                    <p className={classes.subText} key={tax.name}>
                        {tax.name}: <FormatCurrency number={tax.amount} />
                    </p>
                        )
                    )}
                    {(billingType === QuotesQuoteBillingTypeChoices.Direct || billingType === QuotesQuoteBillingTypeChoices.Urgency)
                        && (
                            <p className={classes.subText}>
                                (DIFAL <FormatCurrency number={taxes?.find(tax => tax.name === 'DIFAL')?.amount} />)*
                            </p>
                        )
                    }
                    {billingType === QuotesQuoteBillingTypeChoices.Indirect
                        && (
                            <p className={classes.subText}>
                                (ICMS_ST_FRONTEIRA <FormatCurrency number={taxes?.find(tax => tax.name === 'ICMS_ST_FRONTEIRA')?.amount} />)*
                            </p>
                        )
                    }
                </Box>
            </AccordionDetails>
        </Accordion>
    )
}

const useStyles = makeStyles()(theme => ({
    subText: {
        margin: theme.spacing(0.4),
    },

    expanded: {
        margin: '0!important',
    },

    summaryContent: {
        justifyContent: 'flex-start',
    }
}))
