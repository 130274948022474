import {FC} from 'react';

import {ProductSubcategoryFragment, useGetProductSubcategoriesQuery} from 'generated/graphql';

interface LazyProductCategoryFromSubcategoryProps {
    id: string;
};

export const LazyProductCategoryFromSubcategory: FC<LazyProductCategoryFromSubcategoryProps> = ({id}) => {
    const {data: {productSubcategories = [] as ProductSubcategoryFragment[]} = {}} = useGetProductSubcategoriesQuery();
    const subcategory = productSubcategories?.find(subcategory => subcategory.id === id);
    return <>{subcategory?.category?.name}</>;
};
