import {FC} from 'react';

import {useGetProductSubcategoriesQuery} from 'generated/graphql';

interface LazyProductSubCategoryProps {
    id: string;
};

export const LazyProductSubCategory: FC<LazyProductSubCategoryProps> = ({id}) => {
    const {data} = useGetProductSubcategoriesQuery();
    const subcategory = data?.productSubcategories?.find(subcategory => subcategory.id === id);
    return <>{subcategory?.name}</>;
};
