import {FieldHelperProps} from 'formik';
import {FC, useEffect} from 'react';

import {FormatCurrency} from 'components/currency/format-currency';
import {QuotedPaymentMethodFormInputValues} from 'forms/quotes/quote-form'
import {
    MinimalQuotedPaymentMethodFragment,
    MinimalQuotedProductFragment,
    ProductTaxFragment,
    QuotedPaymentMethodFormInput,
} from 'generated/graphql';
import {calculatePaymentMethodTotalPriceWithTax, calculateTaxesPaymentMethodAggregate} from 'utils/quotes/calculator';

interface PaymentMethodTotalPriceWithTaxProps {
    products: MinimalQuotedProductFragment[];
    paymentMethod: Omit<MinimalQuotedPaymentMethodFragment, 'originalPaymentMethod'>;
    fieldHelpers?: FieldHelperProps<QuotedPaymentMethodFormInputValues[]>;
    currentPaymentMethods?: QuotedPaymentMethodFormInput[];
    taxes: ProductTaxFragment[];
};

export const PaymentMethodTotalPriceWithTax: FC<PaymentMethodTotalPriceWithTaxProps> = ({
    products,
    paymentMethod,
    fieldHelpers,
    currentPaymentMethods,
    taxes,
}) => {
    const price = calculatePaymentMethodTotalPriceWithTax(products, paymentMethod, taxes);

    useEffect(() => {
        if (currentPaymentMethods && !!taxes.length) {
            fieldHelpers?.setValue(calculateTaxesPaymentMethodAggregate(currentPaymentMethods, paymentMethod, taxes, products) as QuotedPaymentMethodFormInputValues[]);
        }
    }, [taxes, price]);

    return <FormatCurrency number={price} />;
};
